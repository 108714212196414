import { Swiper } from 'swiper'
import { Navigation, Pagination, Autoplay } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

const swiper = new Swiper(".swiper", {
	modules: [Navigation, Pagination, Autoplay],
  direction: 'horizontal',
  loop: true,
	autoplay: { delay: 3000 },
	speed: 500,
	slidesPerView: "auto",

  pagination: {
    el: '.swiper-pagination',
		clickable: true
  },

  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  }
})

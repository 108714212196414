import PhotoSwipeLightbox from "photoswipe/lightbox"
import "photoswipe/style.css"

const lightbox = new PhotoSwipeLightbox({
  gallery: "[photo-swipe-gallery]",
  children: "a",
	showHideAnimationType: "fade",
  pswpModule: () => import("photoswipe")
})

lightbox.init()

import { assert_instance } from "./utils"

function initialize(navbar: HTMLElement): void {
	const toggle_menu = navbar.querySelector("button")
	assert_instance(toggle_menu, HTMLButtonElement)

	toggle_menu.onclick = () => navbar.classList.toggle("expanded")
}

document.querySelectorAll("#navbar")
	.forEach(element => {
		assert_instance(element, HTMLElement)
		initialize(element)
	})

export function assert_instance<T>(
	object: unknown,
	constructor: { new(): T },
	message?: string
): asserts object is T {
  if (!(object instanceof constructor))
    throw new Error(message ?? "")
}

export function assert_not_null<T>(
	object: T,
	message?: string
): asserts object is Exclude<T, null | undefined> {
  if (object === null || object === undefined)
    throw new Error(message)
}